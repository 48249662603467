import { Checkboxes } from "./Checkboxes";
import { Date } from "./Date";
import { DrawingPadItem as DrawingPad } from "./DrawingPadItem/DrawingPadItem";
import { DateAndTime } from "./DateAndTime/DateAndTime";
import { Dropdown } from "./Dropdown";
import { FileUpload } from "./FileUpload/FileUpload";
import { NumberInput } from "./NumberInput";
import { ParagraphText } from "./ParagraphText";
import { RadioButtons } from "./RadioButtons";
import { SingleLineText } from "./SingleLineText";
import { StaticText } from "./StaticText";
import { Time } from "./Time";
import { VasScaleItem as VasScale } from "./VasScaleItem/VasScaleItem";
import { ItemType } from "components/Form/shared/item-type";

const itemComponents = {
    Checkboxes,
    Date,
    DateAndTime,
    DrawingPad,
    Dropdown,
    FileUpload,
    Number: NumberInput,
    ParagraphText,
    RadioButtons,
    SingleLineText,
    StaticText,
    Time,
    VasScale,
};

export const getItemComponent = (itemType, htmlType) => {
    if (itemType === ItemType.FILE_UPLOAD && htmlType === "drawingpad") {
        return DrawingPad;
    }

    return itemComponents[itemType];
};
