
export const ItemType = Object.freeze({
    DATE: "Date",
    DATE_AND_TIME: "DateAndTime",
    DROPDOWN: "Dropdown",
    TIME: "Time",
    SINGLE_LINE_TEXT: "SingleLineText",
    PARAGRAPH_TEXT: "ParagraphText",
    NUMBER: "Number",
    STATIC_TEXT: "StaticText",
    SECTION_BREAK: "SectionBreak",
    CHECKBOXES: "Checkboxes",
    RADIO_BUTTONS: "RadioButtons",
    FILE_UPLOAD: "FileUpload", // Can be either file upload or drawing pad depending on htmlType
    VAS_SCALE: "VasScale",
});
