import PropTypes from "prop-types";
import React, { useEffect, useContext } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { UserContext } from "context/UserContext";
import { accountService } from "services/account";
import { authenticationService } from "services/authentication";
import { useIdleTimer } from 'react-idle-timer/legacy'
import { onChangePinMessage } from 'utils/broadcastChannelUtils';

const IDLE_TIMEOUT = 1000 * 60 * 20; // Timeout in milliseconds (20 min)
export function ProtectedRoute({ component: Component, ...rest }) {
  const {
    authenticated,
    setAuthenticated,
    userMustChangePinCode,
    setUserMustChangePinCode,
    busyFormItems
  } = useContext(UserContext);

  const history = useHistory();
  const isAuthenticated = authenticationService.isLoggedIn();
  const doesUserMustChangePinCode = accountService.userMustChangePinCode();
  const shouldRedirectToChangePin = doesUserMustChangePinCode && "/change-pin" !== rest.path;

  // trigger on component mount, ensure correct authenticated state
  useEffect(() => {
    if (authenticated && !isAuthenticated) {
      setAuthenticated(false);
    }
    if (userMustChangePinCode && !doesUserMustChangePinCode) {
      setUserMustChangePinCode(false);
    }
  });

  const handleOnIdle = () => {
    authenticationService.logout();
  };
  
  const { pause, resume } = useIdleTimer({
    timeout: IDLE_TIMEOUT,
    onIdle: handleOnIdle,
    debounce: 500,
    crossTab: true,
    syncTimers: 2
  });
  
  useEffect(() => {
    // Pause idle timer to ensure busy item has time to complete
    if (busyFormItems.length) {
      pause();
    } else {
      resume();
    }
  }, [busyFormItems, pause, resume]);


  const params = new URLSearchParams(window.location.search);
  const loginHint = params.get("login_hint");

  useEffect(() => {
    onChangePinMessage(() => history.push("/change-pin"));
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          shouldRedirectToChangePin ? (
            <Redirect to="/change-pin" />
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to={{pathname: "/login", state: { loginHint }}}/>
        )
      }
    />
  );
}

ProtectedRoute.propTypes = {
  component: PropTypes.elementType,
};
