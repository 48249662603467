const CHANGE_PIN_MESSAGE = "change-pin";
class BroadcastChannelUlti {
    constructor(channelName){
        this.channel = new BroadcastChannel(channelName);
    }

    postMessage(message){
        this.channel.postMessage(message);
    }

    onMessage(callback) {
        this.channel.onmessage = (event) => {
            callback(event.data);
        }
    }

    closeChannel = () => this.channel.close();
}

const broadcastChannel = new BroadcastChannelUlti('vm-channel');

export function onPostChangePinMessage(){
    broadcastChannel.postMessage(CHANGE_PIN_MESSAGE);
}

export function onChangePinMessage(callback){
    broadcastChannel.onMessage((message) => {
        if(message === CHANGE_PIN_MESSAGE){
            callback();
        }
      })
}

export default  broadcastChannel;